export default {
	getLabelFromStop(stop) {
		return `${stop.vesselname}`;
	},

	getSecondLabelFromStop(stop) {
		let etaLocal = this.getTimeLabelFromUTCstring(stop.etalocal);
		let etdLocal = this.getTimeLabelFromUTCstring(stop.etdlocal);

		return `${etaLocal} / ` + `${etdLocal}`;
	},

	getLabelFromBlock(block) {
		return `${block.name}`;
	},

	getSecondLabelFromBlock(block) {
		let dateini = this.getTimeLabelFromUTCstring(block.dateini);
		let dateend = this.getTimeLabelFromUTCstring(block.dateend);

		return `${dateini} / ` + `${dateend}`;
	},

	getLabelFromBooking(booking) {
		return `${booking.vesselname}`;
	},

	getSecondLabelFromBooking(booking) {
		let etaplannerlocal = this.getTimeLabelFromUTCstring(booking.etaplannerlocal);
		let etdplannerlocal = this.getTimeLabelFromUTCstring(booking.etdplannerlocal);

		return `${etaplannerlocal} / ` + `${etdplannerlocal}`;
	},

	getTimeLabelFromUTCstring: function (utcString) {
		let date = new Date(utcString);
		let hours = date.getHours();
		let minutes = date.getMinutes();

		if (minutes < 10) {
			minutes = `0${minutes}`;
		}

		if (isNaN(hours) || isNaN(minutes)) {
			return '';
		}

		return `${hours}:${minutes}`;
	},

	getTimeLabelForPopUp: function (utcString) {
		let date = new Date(utcString);
		let hours = date.getHours();
		let preminutes = date.getMinutes() >= 0 && date.getMinutes() < 10 ? '0' : '';
		let minutes = ':' + preminutes + date.getMinutes();
		let ending = 'h';

		return hours + minutes + ending;
	},

	getDateTimeLabelForPopUp: function (utcString) {
		const date = new Date(utcString);

		const day = String(date.getDate()).padStart(2, '0');
		const month = String(date.getMonth() + 1).padStart(2, '0');
		const year = date.getFullYear();
		const hours = String(date.getHours()).padStart(2, '0');
		const minutes = String(date.getMinutes()).padStart(2, '0');

		return `${day}/${month}/${year} - ${hours}:${minutes}`;
	},

	getLabelFillColorFromBackgroundColor(bgColor) {
		return parseInt(bgColor.replace('#', ''), 16) > 0xffffff / 2 ? '#000' : '#fff';
	},

	getDropShadowColorFromBackgroundColor(bgColor) {
		return parseInt(bgColor.replace('#', ''), 16) > 0xffffff / 2 ? '#fff' : '#000';
	},

	capitalizeFirstLetter: function (string) {
		return string ? string.charAt(0).toUpperCase() + string.slice(1).toLowerCase() : '';
	}
};
