import { dia, ui, shapes, elementTools, util, V, g } from '@clientio/rappid';
import JointSettings from '../JointSettings.js';

import { JointBoardTypes, JointItemTypes } from '../items/JointTypes.js';
import JointUtils from './JointUtils.js';
class JointPopUp {
	constructor(operationsModel, target, type, $puiI18n) {
		this.operationsModel = operationsModel;
		this.$puiI18n = $puiI18n;
		this.target = target;
		this.type = type;

		switch (this.operationsModel.itemType) {
			case JointItemTypes.ResourceAllocation: {
				let resourceAllocationDate = new Date(this.operationsModel.resourceallocation.plannerStartDate);
				this.formattedDate = resourceAllocationDate.getDate() + ' ' + resourceAllocationDate.toLocaleDateString('es-ES', { month: 'short' });
				break;
			}
			case JointItemTypes.ResourceBlock: {
				let resourceBlockDate = new Date(this.operationsModel.resourceblock.plannerStartDate);
				this.formattedDate = resourceBlockDate.getDate() + ' ' + resourceBlockDate.toLocaleDateString('es-ES', { month: 'short' });
				break;
			}
		}

		let c, p;

		if (this.operationsModel.itemType == JointItemTypes.ResourceBlock) {
			c = this.getBlockPopUp();
			p = 25;
		} else {
			switch (this.type) {
				case 'main':
					c = this.getMainResourceAllocationPopUp();
					p = 120;
					break;
				case 'resize':
					c = this.getResizeResourceAllocationPopUp();
					p = 145;
					break;
			}
		}

		this.popUp = new ui.Popup({
			content: c,
			target: this.target,
			//target: rect.findView(paper).el,
			padding: p,
			//anchor: settings.anchor,
			position: 'top',
			//scale,
			arrowPosition: 'bottom'
		});

		this.popUp.render();
	}

	updateContent(operationsModel) {
		this.operationsModel = operationsModel;

		if (this.operationsModel.itemType == JointItemTypes.ResourceBlock) {
			this.popUp.options.content = this.getBlockPopUp();
		} else {
			switch (this.type) {
				case 'main':
					this.popUp.options.content = this.getMainResourceAllocationPopUp();
					break;
				case 'resize':
					this.popUp.options.content = this.getResizeResourceAllocationPopUp();
					break;
			}
		}

		this.popUp.render();
		ui.Popup.update();
	}

	getMainResourceAllocationPopUp() {
		let mobilizationTime = this.operationsModel.resourceallocation.plannerMobilizationTime;
		let demobilizationTime = this.operationsModel.resourceallocation.plannerDemobilizationTime;

		let originalStartFormattedDate = JointUtils.getTimeLabelForPopUp(this.operationsModel.resourceallocation.plannerStartDate);
		let originalEndFormattedDate = JointUtils.getTimeLabelForPopUp(this.operationsModel.resourceallocation.plannerEndDate);

		let estimatedStartFormattedDateAuth = this.operationsModel.resourceallocation.authstartdate
			? JointUtils.getDateTimeLabelForPopUp(this.operationsModel.resourceallocation.authstartdate)
			: JointUtils.getDateTimeLabelForPopUp(this.operationsModel.resourceallocation.plannerStartDate);
		let estimatedEndFormattedDateAuth = this.operationsModel.resourceallocation.authstartdate
			? JointUtils.getDateTimeLabelForPopUp(this.operationsModel.resourceallocation.authenddate)
			: JointUtils.getDateTimeLabelForPopUp(this.operationsModel.resourceallocation.plannerEndDate);
		let realStartFormattedDate = JointUtils.getDateTimeLabelForPopUp(this.operationsModel.resourceallocation.actualstartdate);
		let realEndFormattedDate = JointUtils.getDateTimeLabelForPopUp(this.operationsModel.resourceallocation.actualenddate);
		let statusResource = this.operationsModel.resourceallocation.resourceallocationstatus;

		let result = '';
		if (statusResource == 'PR' || statusResource == 'FI' || statusResource == 'VA') {
			result += `<span style='font-weight:bold;'>${this.$puiI18n.t('form.resourceallocation.startrealdate')}: </span>`;
			result += realStartFormattedDate + `<br />`;
		}

		if (statusResource == 'FI' || statusResource == 'VA') {
			result += `<span style='font-weight:bold;'>${this.$puiI18n.t('form.resourceallocation.endrealdate')}: </span>`;
			result += realEndFormattedDate + `<br />`;
		}

		return [
			`<div style='background-color: blue; color:white; font-weight:bold; margin:-10px -10px 5px -10px; padding:6px 10px;'>${this.operationsModel.resourceallocation.description}</div>`,
			//`<span style='font-weight:bold;'>Fecha: </span>${this.formattedDate}<br />`,
			`<span style='font-weight:bold;'>${this.$puiI18n.t('form.resourceallocation.time')}: </span>`,
			originalStartFormattedDate + ' - ' + originalEndFormattedDate + `<br />`,
			mobilizationTime && `<span style='font-weight:bold;''>${this.$puiI18n.t('form.resourceallocation.mobilization')}: </span>`,
			mobilizationTime + ` min<br />`,
			demobilizationTime && `<span style='font-weight:bold;''>${this.$puiI18n.t('form.resourceallocation.demobilization')}: </span>`,
			demobilizationTime + ` min<br />`,
			`<span style='font-weight:bold;'>${this.$puiI18n.t('form.resourceallocation.startestimateddate')}: </span>`,
			estimatedStartFormattedDateAuth + `<br />`,
			`<span style='font-weight:bold;'>${this.$puiI18n.t('form.resourceallocation.endestimateddate')}: </span>`,
			estimatedEndFormattedDateAuth + `<br />`,
			result,
			//`<span style='font-weight:bold;'>Puerto: </span>${this.operationsModel.resourceallocation.portname}<br />`,
			//`<button>Asignar</button>`,
			`</div>`
		].join('');
	}

	getResizeResourceAllocationPopUp() {
		let originalStartFormattedDate = JointUtils.getTimeLabelForPopUp(this.operationsModel.resourceallocation.plannerStartDate);
		let originalEndFormattedDate = JointUtils.getTimeLabelForPopUp(this.operationsModel.resourceallocation.plannerEndDate);

		let extendedStartFormattedDate = JointUtils.getTimeLabelForPopUp(this.operationsModel.resourceallocation.extendedPlannerStartDate);
		let extendedEndFormattedDate = JointUtils.getTimeLabelForPopUp(this.operationsModel.resourceallocation.extendedPlannerEndDate);

		return [
			`<div style='background-color: #303E59; color:white; margin:-10px; padding: 20px;'>`,
			`<div style='font-weight:bold; padding-bottom: 5px;'>${originalStartFormattedDate + ' - ' + originalEndFormattedDate}</div>`,
			`<div style='padding-bottom: 5px;'>(${extendedStartFormattedDate + ' - ' + extendedEndFormattedDate})</div>`,
			`<div style='margin-bottom: 5px;'>${this.formattedDate}</div>`,
			`<hr style='border-top: 1px white solid;'>`,
			this.operationsModel.validAllocation
				? `<div style='font-weight:bold; color: #24984F; margin-top: 5px; margin-bottom: -4px;'>Disponible</div>`
				: `<div style='font-weight:bold; color: #C93018; margin-top: 5px; margin-bottom: -4px;'>No disponible</div>`,
			`</div>`
		].join('');
	}

	getBlockPopUp() {
		let startFormattedDate = JointUtils.getTimeLabelForPopUp(this.operationsModel.resourceblock.plannerStartDate);
		let endFormattedDate = JointUtils.getTimeLabelForPopUp(this.operationsModel.resourceblock.plannerEndDate);

		return [
			`<div style='background-color: #303E59; color:white; margin:-10px; padding: 20px;'>`,
			`<div style='font-weight:bold; padding-bottom: 5px;'>${startFormattedDate + ' - ' + endFormattedDate}</div>`,
			`</div>`
		].join('');
	}
}

export default JointPopUp;
